import { Alert, Backdrop, Box, Button, Card, CardContent, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, Input, InputLabel, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import LoginIcon from '@mui/icons-material/Login';
import HelpIcon from '@mui/icons-material/Help';
import Footer from '../komponenter/Bunn';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const APIURL = process.env.REACT_APP_APIURL;

function Login() {
  useEffect(() => {
    document.title = 'Din Side - Justervesenet';
    const jvbruker = sessionStorage.getItem('jvbruker');
    const jvtoken = sessionStorage.getItem('jvtoken');
    if ((jvbruker) || (jvtoken)) {
      window.location.assign("/loggut");
    }

  }, []);
  const [isLoggingOn, setisLoggingOn] = useState(false);
  const [AuthError, setAuthError] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setisLoggingOn(!isLoggingOn);
    event.preventDefault();
    var data = new FormData(event.currentTarget);
    var formdata = {
      brukernavn: data.get('brukernavn'),
      passord: data.get('passord'),
    };
    axios.post(APIURL + "/token", formdata, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
      .then((response: any) => {
        setisLoggingOn(false);
        if (response.status === 200) {
          setAuthError(false);
          sessionStorage.setItem('jvbruker', response.data.epost);
          sessionStorage.setItem('jvbrukerverifisert', response.data.epostverifisert);
          sessionStorage.setItem('jvnavn', response.data.navn);
          sessionStorage.setItem('jvmobilnummer', response.data.mobilnummer);
          sessionStorage.setItem('jvmobilnummerverifisert', response.data.mobilnummerverifisert);
          sessionStorage.setItem('jvtoken', response.data.accesstoken);
          sessionStorage.setItem('jvaktorid', response.data.aktorid);
          sessionStorage.setItem('jvaktornavn', response.data.aktornavn);
          sessionStorage.setItem('jvaktororgnummer', response.data.aktororgnr);
          sessionStorage.setItem('jvaktorverifisert', response.data.aktorverifisert);
          window.location.assign("/");
        }
        else {
          setAuthError(true);
        }
      })
      .catch((e: Error) => {
        setisLoggingOn(false);
        setAuthError(true);
      });
  }

  const [brukervilkaargodtatt, setbrukervilkaargodtatt] = useState(false);
  const handleGodtaLogin = (data: any) => {
    if (data.target.checked) {
      setbrukervilkaargodtatt(true);
    } else {
      setbrukervilkaargodtatt(false);
    }
  }

  return (
    <main>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoggingOn}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToppMeny />
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box m={2}>
            <Typography variant="h5" color="text.primary" component="h2" gutterBottom>
              <Text tid="forsidenoverskrift" /><p></p>
            </Typography>
            <Typography variant="body1" color="text.secondary" component="h2" gutterBottom>
              <Text tid="forsidenoverskrift2" /><p></p>
              <Text tid="forsidenoverskrift3" /><p></p>
              <ul>
                <li><Text tid="forsidenpunkt1" /></li>
                <li><Text tid="forsidenpunkt2" /></li>
                <li><Text tid="forsidenpunkt3" /></li>
              </ul>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} mt={2} p={2}>
          <Typography variant="h5" component="h2">
            <Text tid="loginoverskrift" />
          </Typography>
          <p></p>
          <Card variant="outlined">
            <CardContent>
              {AuthError && <Box sx={{ marginTop: 3, width: '100%' }}>
                <Alert
                  variant="outlined" severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAuthError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  <Text tid="feilbrukerpass" />
                </Alert>
              </Box>
              }
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <FormControl fullWidth sx={{ mt: 1 }} variant="standard">
                  <InputLabel htmlFor="brukernavn"><Text tid="brukernavn" /></InputLabel>
                  <Input
                    autoFocus
                    id="brukernavn"
                    name="brukernavn"
                    fullWidth
                    required
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2, mb: 2 }} variant="standard">
                  <InputLabel htmlFor="passord"><Text tid="passord" /></InputLabel>
                  <Input
                    id="passord"
                    name="passord"
                    type="password"
                    fullWidth
                    required
                  />
                </FormControl>
                <FormControlLabel
                  control={<Checkbox />}
                  label={
                    <div>
                      <span>{Text({ tid: "godtabrukervilkaar1" })}</span>
                      <span> <a href="https://kvalitet.justervesenet.no/getdoc.aspx?id=1120" target="_blank" rel="noreferrer">{Text({ tid: "godtabrukervilkaar2" })}</a></span>
                      <span> {Text({ tid: "godtabrukervilkaar3" })}</span>
                    </div>
                  }
                  onChange={handleGodtaLogin}
                />
                <p></p>
                <Button
                  type="submit"
                  color="success"
                  variant="contained"
                  startIcon={<LoginIcon />}
                  sx={{ mr: 1 }}
                  disabled={!brukervilkaargodtatt}
                >
                  <Text tid="logginn" />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddCircleIcon />}
                  href="/nykonto"
                  sx={{ mr: 1 }}
                >
                  <Text tid="nykonto" />
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  href="/glemtpassord"
                  startIcon={<HelpIcon />}
                >
                  <Text tid="glemtpassord" />
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </main>
  )
}

export default Login;