import { Box, Card, CardContent, Link, Typography } from '@mui/material'
import React from 'react'
import { Text } from '../komponenter/Tekst'
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import FolderIcon from '@mui/icons-material/Folder';

const iconMapping: { [key: string]: React.ElementType } = {
    LocalTaxiIcon: LocalTaxiIcon,
    PointOfSaleIcon: PointOfSaleIcon,
    FolderIcon: FolderIcon,
};

interface TjenesteknapperProps {
    link: string;
    Tittel: string;
    Info: string;
    Icon: string;
}

const Tjenesteknapper: React.FC<TjenesteknapperProps> = ({ link, Icon, Tittel, Info }) => {
    const IconComponent = iconMapping[Icon] || LocalTaxiIcon;
    return (
        <div>
            <Box sx={{ display: 'flex', width: 280, height: 250 }}>
                <Link href={link} underline="none" sx={{ width: '100%', height: '100%' }}>
                    <Card variant="outlined" className="CardLink" sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <CardContent>
                            <IconComponent className='jvboxicon text-warning' />
                                <Typography variant="h6" color="text.secondary" align="center">
                                    <Text tid={Tittel} />
                                </Typography>
                                <hr></hr>
                                <Typography variant="body2" color="text.secondary">
                                    <Text tid={Info} />
                                </Typography>
                        </CardContent>
                    </Card>
                </Link>
            </Box>
        </div>
    )
}

export default Tjenesteknapper;